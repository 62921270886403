import React from 'react'
import { Box } from 'components/primitives/Box'
import { Grid } from 'react-styled-flexboxgrid'
import { H2 } from 'components/primitives/Text'
import { Download } from '../shared/Download'
import { FullCenterView } from 'components/primitives/Views'

export const GetNow: React.FC = () => {
  return (
    <Box bg='accent1' py='big'>
      <Grid>
        <H2 textAlign='center' fontSize='3.6rem'>
          Start your own diary now
        </H2>

        <FullCenterView>
          <Download place='footer-cta' />
        </FullCenterView>
      </Grid>
    </Box>
  )
}
