import styled from 'styled-components'
import { Text } from './Text'
import {
  typography,
  SpaceProps,
  ColorProps,
  LayoutProps,
  FlexboxProps,
  TypographyProps,
  space,
  color,
  layout,
  flexbox,
  variant,
  shadow,
  ShadowProps
} from 'styled-system'
import { colors, font, fontWeights, fontSizes } from 'services/theme'

const buttonVariants = {
  default: {
    bg: 'accent3',
    color: 'text',
    description: colors.textAccent1
  },
  primary: {
    bg: 'color1',
    color: 'text',
    description: colors.color4
  },
  clear: {
    bg: 'none',
    color: 'text',
    description: colors.textAccent1
  },
  clearDestruct: {
    bg: 'none',
    color: 'destructive',
    description: colors.textAccent1
  }
}

type ButtonProps = SpaceProps &
  ColorProps &
  LayoutProps &
  FlexboxProps &
  TypographyProps &
  ShadowProps & {
    variant?: 'default' | 'primary' | 'clear' | 'clearDestruct'
  }

export const Button = styled.div<ButtonProps>`
  ${variant({
    variants: buttonVariants
  })}
  border-radius: 2.75rem;
  cursor: pointer;
  font-family: ${font};
  fontSize: ${fontSizes.regular};
  fontWeight: ${fontWeights.medium};
  transition: var(--transition);
  ${space}
  ${color}
  ${layout}
  ${flexbox}
  ${typography}
  ${shadow}

  ${props =>
    props.variant === 'default' &&
    `
    :active {
      background: ${colors.accent4};
    }
  `}

  ${props =>
    props.variant === 'primary' &&
    props.theme.id === 'dark' &&
    `
    color: ${colors.accent4};
  `}
  
  :focus {
    outline: none;
  }
`

Button.defaultProps = {
  minWidth: '10rem',
  fontWeight: 'medium',
  textAlign: 'center',
  p: '1.35rem 2rem',
  display: 'inline-block',
  variant: 'default'
}

export const buttonHeight = '5.5rem'

export const ButtonDescription = styled(Text)`
  float: right;
  margin-left: 2rem;
`

export const ButtonWithDescription = styled(Button)`
  text-align: initial;

  ${ButtonDescription} {
    color: ${props => buttonVariants[props.variant || 'primary'].description};
  }
`

type Round = { sizeRem?: number; onClick?: () => void }
const roundSize = 5.5
export const RoundButton = styled(Button)<Round>`
  border-radius: ${props => (props.sizeRem || roundSize) / 2}rem;
  height: ${props => props.sizeRem || roundSize}rem;
  line-height: ${props => props.sizeRem || roundSize}rem;
  min-width: 0;
  padding: 0;
  width: ${props => props.sizeRem || roundSize}rem;
`

export const HTMLButton = styled.button`
  background: transparent;
  border: none;
  display: block;

  :focus {
    outline: none;
  }
`
