import React from 'react'
import { Box } from 'components/primitives/Box'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import { P, H2 } from 'components/primitives/Text'
import { CenteredColumnView } from 'components/primitives/Views'
import styled from 'styled-components'
import { media } from 'components/primitives/Responsive'

const STEPS = [
  {
    id: '01',
    heading: 'Track',
    desc: `A usage log keeps you in check. You can add your consumption in just a few clicks. Save your most common dosages to make it even easier!`
  },
  {
    id: '02',
    heading: 'Analyze',
    desc: `Our dashboard provides a lot of useful stats regarding your usage. The calendar gives you a clear overview of how much and how often you consume.`
  },
  {
    id: '03',
    heading: 'Set Goals',
    desc: `Never forget taking your vitamins again. Set your own goals and reminders to keep up with your plan. Live happy and healthy.`,
    subtext: 'COMING SOON'
  }
]

export const Steps: React.FC = () => {
  return (
    <Box bg='accent1' py='big' id='learn'>
      <Grid>
        <Row>
          {STEPS.map(step => (
            <Col xs={12} md={4} key={step.id}>
              <CenteredColumnView>
                <Subtext empty={!step.subtext}>{step.subtext}&zwnj;</Subtext>
                <H2 textAlign='center' fontSize='3.6rem'>
                  {step.heading}
                </H2>
                <P
                  textAlign='justify'
                  fontSize='2rem'
                  style={{ textAlignLast: 'center', order: 3 }}
                  color='textAccent1'
                  mx='tiny'
                >
                  {step.desc}
                </P>
              </CenteredColumnView>
            </Col>
          ))}
        </Row>
      </Grid>
    </Box>
  )
}

interface SubtextProps {
  empty?: boolean
}

const Subtext = styled(P)<SubtextProps>`
  ${media.xs} {
    order: 2;
    ${props => props.empty && `display: none;`}
  }
`

Subtext.defaultProps = {
  textAlign: 'center',
  color: 'color1',
  fontSize: '1.2rem',
  fontWeight: 'medium'
}
