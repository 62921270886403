import styled from 'styled-components'
import {
  space,
  SpaceProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps
} from 'styled-system'

type Props = SpaceProps & LayoutProps & FlexboxProps

export const Img = styled.img<Props>`
  ${space}
  ${layout}
  ${flexbox}
`

Img.defaultProps = {
  display: 'inline-block',
  width: '100vw',
  maxWidth: '100vw',
  maxHeight: '100vh'
}
