import React from 'react'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { H1, Text } from 'components/primitives/Text'
import { Section } from 'components/primitives/Section'
import { media, Only } from 'components/primitives/Responsive'
import styled from 'styled-components'
import mockupDashboard from 'assets/mockups/ip10-dashboard.png'
import { CenteredColumnView } from 'components/primitives/Views'
import { Img } from 'components/primitives/Img'
import { space } from 'services/theme'
import { Download } from '../shared/Download'

export const Header: React.FC = () => {
  return (
    <Wrapper as='header'>
      <Row>
        <Col xs={12} md={5} style={{ textAlign: 'center' }}>
          <Img src={mockupDashboard} maxWidth='30rem' />
        </Col>
        <Col xs={12} md={7}>
          <CenteredColumnView>
            <H1>
              Keep track of every <Only only='minMd' display='block' />
              substance you consume
            </H1>
            <Text as='p' color='textAccent1'>
              Meet Dose &mdash; your personal diary of vitamins,{' '}
              <Only only='minMd' display='block' />
              suppliments, medication, and more.
            </Text>

            <Section mt='medium'>
              <Download place='header' />
            </Section>
          </CenteredColumnView>
        </Col>
      </Row>
    </Wrapper>
  )
}

const Wrapper = styled(Grid)`
  margin-bottom: ${space.big};

  ${media.maxSm} {
    text-align: center;
  }

  ${media.minMd} {
    ${H1} {
      font-size: 5.6rem;
      line-height: 1.2em;
    }
    ${Text} {
      font-size: 2.6rem;
    }
  }

  ${CenteredColumnView} {
    height: 100%;
  }

  ${media.lg} {
    ${CenteredColumnView} {
      float: right;
    }
  }
`
