import React from 'react'
import SEO from 'components/shared/SEO'
import { Home } from 'components/Landing/Home/Home'

const Index: React.FC = () => {
  return (
    <>
      <SEO noTitleTemplate />
      <Home />
    </>
  )
}

export default Index
