import React from 'react'
import { Section } from 'components/primitives/Section'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { H2, Text } from 'components/primitives/Text'
import { colors, space } from 'services/theme'
import { RoundButton } from 'components/primitives/Button'
import { FullCenterView } from 'components/primitives/Views'
import Star from 'assets/icons/fa/star-solid.svg'
import Lock from 'assets/icons/fa/lock-alt-solid.svg'
import Moon from 'assets/icons/fa/moon-solid.svg'
import Calendar from 'assets/icons/fa/calendar-star-solid.svg'
import Pills from 'assets/icons/fa/pills-solid.svg'
import BallPile from 'assets/icons/fa/ball-pile-solid.svg'

const FEATURES = [
  {
    Icon: Moon,
    name: 'Dark Theme',
    description: `Perfect for dark environments. Easier on your eyes.`
  },
  {
    Icon: Lock,
    name: 'Pin Code',
    description: `Prevent others from seeing your data by using a PIN code.`
  },
  {
    Icon: Calendar,
    name: 'Calendar Overview',
    description: `Easily visualize your usage with simple and clean calendar.`
  },
  {
    Icon: BallPile,
    name: 'Quick Doses',
    description: `Save your most common dosages and add them with just one click.`
  },
  {
    Icon: Pills,
    name: 'Custom Substances',
    description: `Add any vitamin or suppliment you use. Track everything in one place.`
  },
  {
    Icon: Star,
    name: 'Meaningful Stats',
    description: `See how much you've spent, how often you consume, and more.`
  }
]

export const Features: React.FC = () => {
  return (
    <Section my='big'>
      <Grid style={{ paddingBottom: space.big }}>
        <Row>
          {FEATURES.map(feature => (
            <Col xs={12} md={4} key={feature.name}>
              <RoundButton
                bg='#FDF0DB'
                mt='big'
                mb='small'
                variant='clear'
                style={{ cursor: 'default' }}
              >
                <FullCenterView>
                  <feature.Icon fill={colors.color1} height={26} />
                </FullCenterView>
              </RoundButton>

              <H2>{feature.name}</H2>
              <Text color='textAccent1'>{feature.description}</Text>
            </Col>
          ))}
        </Row>
      </Grid>
    </Section>
  )
}
