import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { Page } from '../shared/Page'
import { Header } from './Header'
import { Steps } from './Steps'
import { Features } from './Features'
import { GetNow } from './GetNow'

export const Home: React.FC<RouteComponentProps> = () => {
  return (
    <Page>
      <Header />
      <Steps />
      <Features />
      <GetNow />
    </Page>
  )
}
